<template>
	<div class="container">
		<!-- <div class="header">
			<img src="../../assets/imgs/mall.png" alt="">
			<div class="text">
				<p class="title">天地乾坤商城</p>
				<div class="line"></div>
			</div>
		</div> -->

		<!-- <div class="search">
			<div class="text">
				<p>智慧家居</p>
				<div class="line"></div>
				<p>智慧社区</p>
				<div class="line"></div>
				<p>全要素疫情防控</p>
			</div>
			
			<el-input style="width: 295px;" placeholder="请输入内容" v-model="search" class="input-with-select">
			    <el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div> -->

		<div class="main">
			<div class="text">
				<div class="top">
					<div class="line"></div>
					<p class="title" @click="getPro('')">all product</p>
					<div class="line"></div>
				</div>

				<div class="active-div">
					<p @click="handleActiveClick(item.id)" :class="{'active': active == item.id}"
						v-for="item of activeArr" :key="item.id">
						{{item.name}}
					</p>
				</div>

				<div class="goods">
					<div class="goods-detals" v-for="item of nowGoods" :key="item.id">
						<img :src="item.imageUrl" alt="" style="width: 245px; height: 232px;">
						<div class="text">
							<p class="title">{{item.title}}</p>
							<p class="content">{{item.info}}</p>
							<div class="btns">
								<button @click="productClick(item)">查看详情</button>
							</div>
						</div>
					</div>


				</div>
				<el-pagination :page-size="4" background layout="prev, pager, next"
					@current-change="handleCurrentChange" :current-page.sync="page" :total="total">
				</el-pagination>
			</div>

			<!-- <div class="apply">
				<p class="title">申请合作</p>
				<div>
					<el-descriptions :column="2">
						<el-descriptions-item label="公司名称">天乾地坤科技有限股份公司</el-descriptions-item>
						<el-descriptions-item label="联系电话">400-066-1909</el-descriptions-item>
						<el-descriptions-item label="邮箱">duan.liu@cklock.cn</el-descriptions-item>
						<el-descriptions-item label="地址">河北省承德市双滦区君泰财富广场A区</el-descriptions-item>
					</el-descriptions>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import { getProduct } from "@/api/home";
	export default {
		data() {
			return {
				search: '',
				activeArr: [],

				active: '',
				page: 1,
				nowGoods: [],
				goods: [],
				homeArr: [],
				comArr: [],
				allArr: [],
				goodsArr: [],
				total: 0
			}
		},

		methods: {

			productClick(item) {
				this.$router.push({ path: '/wisdomProperty', query: { item: JSON.stringify(item) } }) //产品
			},
			handleActiveClick(val) {
				console.log(val)
				if (this.active == val) {
					this.active = ''
				} else {
					this.active = val
				}
				this.page = 1
				this.getPro(val)
			},
			getPro(val) {
				getProduct({ pageNo: 1, pageSize: 4, dictValue: val }).then(response => {
					let data = response.data
					this.nowGoods = data.list
					this.total = data.total
					console.log(this.nowGoods)
				});
			},
			getGoods() {
				if (this.active == '') {
					this.nowGoods = this.goodsArr[this.page - 1]
					this.total = 12
				} else if (this.active == 1) {
					this.nowGoods = this.homeArr[this.page - 1]
					this.total = 6
				} else if (this.active == 2) {
					this.nowGoods = this.comArr[this.page - 1]
					this.total = 3
				} else if (this.active == 3) {
					this.nowGoods = this.allArr[this.page - 1]
					this.total = 4
				}
			},
			handleCurrentChange(val) {
				this.page = val
				this.getGoods()
			},
			handleSeeBtn(id) {
				console.log(JSON.stringify(id))
				this.$router.push({ path: '/product', query: { id: JSON.stringify(id) } })
			}
		},

		created() {
			if (this.$route.query.id) {
				this.active = this.$route.query.id
				this.getPro(this.active)
			}
			// console.log(this.$route.query.id)
			this.nowGoods = JSON.parse(localStorage.getItem("productInfo")) //产品
			this.activeArr = JSON.parse(localStorage.getItem("navigation"))[4].childes
			// activeArr
			console.log(this.activeArr)
			// this.getGoods()

		}
	}
</script>

<style scoped>
	.container {
		min-height: 500px;

	}

	.header {
		position: relative;
	}

	.header img {
		width: 100%;
	}

	.header .text {
		position: absolute;
		top: 35%;
		left: 15%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.header .text .title {
		height: 48px;
		font-size: 48px;
		font-family: SourceHanSansCN-Bold, SourceHanSansCN;
		font-weight: bold;
		color: #FFFFFF;
		margin-bottom: 28px;
	}

	.header .text .line {
		width: 452px;
		height: 5px;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0.1300) 0%, #FFFFFF 45%, rgba(255, 255, 255, 0.1600) 100%);
		border-radius: 6px;
	}


	.search {
		height: 71px;
		background: #F4F8FF;
		padding: 0 211px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.search .text {
		display: flex;
		align-items: center;
	}

	.search .text p {
		font-size: 17px;
		font-family: SourceHanSansCN-Normal, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.search .text .line {
		width: 1px;
		height: 12px;
		background-color: #979797;
		margin: 0 24px;
	}

	.main {
		width: 1200px;
		margin: 0 auto;
		padding: 72px 76px 91px;
		box-sizing: border-box;
	}

	.main .text .top {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.main .text .top .line {
		width: 75px;
		height: 1px;
		background-color: #979797;
		margin: 0 16px;
	}

	.main .text .top .title {
		font-size: 20px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #666666;
		text-transform: uppercase;
	}

	.main .text .active-div {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 17px;
	}

	.main .text .active-div p {
		width: 174px;
		height: 33px;
		border: 1px solid #979797;
		font-size: 16px;
		font-family: SourceHanSansCN-Normal, SourceHanSansCN;
		font-weight: 400;
		color: #999999;
		line-height: 33px;
		text-align: center;
		cursor: pointer;
	}

	.main .text .active-div .active {
		width: 174px;
		height: 33px;
		background: #245ECB;
		border: 1px solid #245ECB;
		color: #FFFFFF;
	}

	.goods {
		display: flex;
		flex-wrap: wrap;
		/* align-items: center; */
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 87px;
		margin-bottom: 50px;
		height: 460px;
		overflow: hidden;
	}

	.goods-detals {
		display: flex;

	}

	.goods-detals .text {
		position: relative;
		top: 17px;
		padding-left: 15px
	}

	.goods-detals .text .title {
		height: 24px;
		font-size: 24px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}

	.goods-detals .text .content {
		width: 220px;
		font-size: 12px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #666666;
		line-height: 14px;
		margin: 13px 0 37px;
		overflow: hidden;
		-webkit-line-clamp: 5;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.btns {
		display: flex;
		justify-content: flex-end;
	}

	.btns button {
		width: 86px;
		height: 25px;
		border-radius: 17px;
		border: 1px solid #184BAB;
		background: #fff;
		line-height: 25px;
		font-size: 12px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #184BAB;
	}

	.el-pagination {
		text-align: center;
	}

	.apply {
		/* height: 312px; */
		background-size: cover;
		padding: 45px 199px 0;
		box-sizing: border-box;
		text-align: center;
	}

	.apply .title {
		height: 23px;
		font-size: 23px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333
	}

	.apply div {
		margin-top: 42px;

	}

	.el-descriptions {
		font-size: 18px;
	}
</style>